import React from 'react';

export default settings => {
    return ({ row }) => {
        let percent;
        const totalLearners = row['descendantRoles.total'];
        if (totalLearners === 0) {
            percent = 0;
        } else if (row.percentVal) {
            percent = row[`percentVal${settings.timepoint || ''}`];
        } else {
            const completedLearners = row[`descendantRoles.completed${settings.timepoint || ''}`];
            percent = Math.round((completedLearners / totalLearners) * 100);
        }

        return <span>{percent}%</span>;
    };
};
