import React, { useState, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import GqlTable from 'cccisd-graphql-table';
import Loader from 'cccisd-loader';
import Tabs from 'cccisd-tabs';
import StudentLauncher from '../../components/StudentLauncher';
import SdqLauncher from '../../components/SdqLauncher';
import studentLauncherQuery from './studentLauncher.graphql';
import sdqDeploymentsQuery from './sdqDeployments.graphql';
import sdqQuery from './sdq.graphql';
import style from './style.css';

const ProviderParticipants = () => {
    const [sdqDeployments, setSdqDeployments] = useState(null);
    useEffect(() => {
        async function getSdqDeployments() {
            const resp = await client.query({
                query: sdqDeploymentsQuery,
                fetchPolicy: 'network-only',
            });
            setSdqDeployments(
                resp.data.deployments.deploymentList.reduce((prev, curr) => {
                    prev[curr.timepoint] = { ...curr };
                    return prev;
                }, {})
            );
        }
        getSdqDeployments();
    }, []);

    if (!sdqDeployments) {
        return (
            <div className={style.loader}>
                <Loader type="inline" loading />
            </div>
        );
    }

    const commonColumns = [
        { name: 'user.username', label: 'Username', sort: true, filter: true },
        { name: 'user.firstName', label: 'First Name', sort: true, filter: true },
        { name: 'user.lastName', label: 'Last Name', sort: true, filter: true },
    ];

    const commonStatusColumnParams = {
        className: 'text-center',
        width: '120px',
        filter: true,
        filterSettings: {
            type: 'selectbox',
            options: [
                { label: 'Not Started', value: 'Not Started' },
                { label: 'In Progress', value: 'In Progress' },
                { label: 'Complete', value: 'Complete' },
            ],
        },
        sort: true,
    };

    const studentLauncherProps = {
        rowKey: 'pawn.pawnId',
        name: 'provider_student_launcher',
        query: studentLauncherQuery,
        columns: [
            ...commonColumns,
            {
                name: 'time1.status',
                label: 'Time 1',
                render: renderProps => <StudentLauncher {...renderProps} timepoint={1} />,
                ...commonStatusColumnParams,
            },
            {
                name: 'time2.status',
                label: 'Time 2',
                render: renderProps => <StudentLauncher {...renderProps} timepoint={2} />,
                ...commonStatusColumnParams,
            },
            {
                name: 'time3.status',
                label: 'Time 3',
                render: renderProps => <StudentLauncher {...renderProps} timepoint={3} />,
                ...commonStatusColumnParams,
            },
            {
                name: 'time4.status',
                label: 'Time 4',
                render: renderProps => <StudentLauncher {...renderProps} timepoint={4} />,
                ...commonStatusColumnParams,
            },
            {
                name: 'user.lastLoginDate',
                label: 'Last Login',
                sort: true,
                filter: true,
                filterSettings: { type: 'date' },
            },
        ],
    };

    const sdqProps = {
        rowKey: 'pawn.pawnId',
        name: 'provider_student_sdq',
        query: sdqQuery,
        columns: [
            ...commonColumns,
            {
                name: 'time1.status',
                label: 'Time 1',
                render: renderProps => (
                    <SdqLauncher {...renderProps} timepoint={1} sdqDeployment={sdqDeployments[1]} />
                ),
                ...commonStatusColumnParams,
            },
            {
                name: 'time2.status',
                label: 'Time 2',
                render: renderProps => (
                    <SdqLauncher {...renderProps} timepoint={2} sdqDeployment={sdqDeployments[2]} />
                ),
                ...commonStatusColumnParams,
            },
            {
                name: 'time3.status',
                label: 'Time 3',
                render: renderProps => (
                    <SdqLauncher {...renderProps} timepoint={3} sdqDeployment={sdqDeployments[3]} />
                ),
                ...commonStatusColumnParams,
            },
            {
                name: 'time4.status',
                label: 'Time 4',
                render: renderProps => (
                    <SdqLauncher {...renderProps} timepoint={4} sdqDeployment={sdqDeployments[4]} />
                ),
                ...commonStatusColumnParams,
            },
        ],
    };

    const tabList = [
        { name: 'sdq', title: 'Staff Surveys', content: <GqlTable {...sdqProps} /> },
        {
            name: 'studentLauncher',
            title: 'Student Launcher',
            content: <GqlTable {...studentLauncherProps} />,
        },
    ];

    return <Tabs tabList={tabList} />;
};

export default ProviderParticipants;
