import React, { useState, useRef, useEffect } from 'react';
import { addHours, addWeeks, differenceInWeeks, format, isSunday, subDays } from 'date-fns';
import _get from 'lodash/get';
import { useQuery } from 'cccisd-react-query';
import { client } from 'cccisd-apollo';
import IconCheck from 'cccisd-icons/checkmark4';
import IconNotification from 'cccisd-icons/notification2';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import Table from 'cccisd-table';
import Tooltip from 'cccisd-tooltip';
import Activities from './Activities';
import deploymentsQuery from './deployments.graphql';
import style from './style.css';

export const dateFormat = 'MM/DD/YYYY';
const IS_YEAR_2 = true;
const Fortress = window.cccisd.fortress;

const ProviderDashboard = () => {
    const [currentDeployment, setCurrentDeployment] = useState(null);
    const modalRef = useRef(null);
    const {
        isLoading,
        error,
        data: deploymentList,
        refetch,
    } = useQuery(['provider_dashbaord'], async () => {
        const resp = await client.query({
            query: deploymentsQuery,
            fetchPolicy: 'network-only',
        });

        let startDate = IS_YEAR_2
            ? _get(resp, 'data.groups.site.fields.startDate2', null)
            : _get(resp, 'data.groups.site.fields.startDate', null);
        if (!startDate) {
            throw new Error('Start Date must be defined for this Site.');
        }
        // add 12 hours to get correct day of week when converting from UTC (database)
        startDate = addHours(new Date(startDate), 12);
        // open Fidelity surveys the Sunday before the program starts
        while (!isSunday(startDate)) {
            startDate = subDays(startDate, 1);
        }

        const now = new Date();
        let endDate = IS_YEAR_2
            ? _get(resp, 'data.groups.site.fields.endDate2', null)
            : _get(resp, 'data.groups.site.fields.endDate', null);
        endDate = endDate ? new Date(endDate) : addWeeks(startDate, 52);
        const weeksSinceStart = differenceInWeeks(addWeeks(now, 1), startDate);
        const weeksInProgram = differenceInWeeks(addWeeks(endDate, 1), startDate);

        let deployments = (_get(resp, 'data.deployments.deploymentList', []) || [])
            .filter(d => (IS_YEAR_2 ? !!d.timepoint && Number(d.timepoint) > 52 : !!d.timepoint))
            .sort((a, b) => Number(a.timepoint) - Number(b.timepoint))
            .map(dep => {
                const timepoint = IS_YEAR_2 ? Number(dep.timepoint) - 52 : Number(dep.timepoint);
                const isAvailable = timepoint <= weeksSinceStart && timepoint <= weeksInProgram;
                const isComplete = isAvailable && !!dep.assignmentProgress.completed;
                const numOfActivities = isComplete && (dep.assignmentProgress.devTags.implementation_activities || 0);

                return {
                    deploymentId: dep.deploymentId,
                    completedAt: dep.assignmentProgress.completedAt,
                    isAvailable,
                    isComplete,
                    timepoint,
                    numOfActivities,
                    devTags: dep.assignmentProgress.devTags,
                    weekStartDate: addWeeks(startDate, timepoint - 1),
                    weekEndDate: subDays(addWeeks(startDate, timepoint), 1),
                };
            });

        return deployments;
    });

    useEffect(() => {
        if (modalRef && modalRef.current && currentDeployment) {
            modalRef.current.open();
        }
    }, [currentDeployment]);

    if (isLoading) {
        return <Loader loading />;
    }

    if (error) {
        return (
            <div className="alert alert-danger">
                <p>{error.message}</p>
            </div>
        );
    }

    let toDoDeployment;
    let deploymentListDesc = [];
    for (let i = deploymentList.length - 1; i >= 0; i--) {
        deploymentListDesc.push(deploymentList[i]);
        if (!toDoDeployment && deploymentList[i].isAvailable && !deploymentList[i].isComplete) {
            toDoDeployment = deploymentList[i];
        }
    }

    return (
        <div>
            <Modal
                ref={modalRef}
                size="large"
                title={
                    currentDeployment
                        ? `Week of ${format(currentDeployment.weekStartDate, dateFormat)} - ` +
                          `${format(currentDeployment.weekEndDate, dateFormat)}`
                        : ''
                }
                render={() => {
                    return (
                        currentDeployment && (
                            <DeploymentPlayer
                                deploymentId={currentDeployment.deploymentId}
                                disableLayout
                                pawnId={Fortress.user.acting.id}
                                pawnHash={Fortress.user.acting.random_hash}
                            />
                        )
                    );
                }}
                afterClose={() => {
                    setCurrentDeployment(null);
                    refetch({ throwOnError: true });
                }}
            />
            {toDoDeployment ? (
                <div className={style.alert}>
                    <span className={style.icon}>
                        <IconNotification spaceRight />
                    </span>
                    <p className={style.message}>
                        Please{' '}
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                setCurrentDeployment(toDoDeployment);
                            }}
                        >
                            complete the implementation survey
                        </a>{' '}
                        for the week of {format(toDoDeployment.weekStartDate, dateFormat)}
                        &nbsp;-&nbsp;
                        {format(toDoDeployment.weekEndDate, dateFormat)}.
                    </p>
                </div>
            ) : (
                <div className={`${style.alert} ${style.upToDate}`}>
                    <span className={style.icon + ' text-success'}>
                        <IconCheck spaceRight />
                    </span>
                    <p className={style.message}>You have no tasks to complete at this time.</p>
                </div>
            )}
            <Table
                name="provider_dashboard_deployments"
                data={deploymentListDesc.filter(d => d.isAvailable)}
                className="table table-condensed table-striped"
                columns={[
                    {
                        name: 'deploymentId',
                        label: 'Status',
                        class: `text-center ${style.smallColumn}`,
                        render: (value, row) => {
                            if (row.isComplete) {
                                return (
                                    <Tooltip
                                        title={`Completed ${format(new Date(row.completedAt), dateFormat + ' h:mma')}`}
                                    >
                                        <button
                                            className="btn btn-default"
                                            type="button"
                                            onClick={() => setCurrentDeployment(row)}
                                        >
                                            Edit
                                        </button>
                                    </Tooltip>
                                );
                            }
                            return (
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => setCurrentDeployment(row)}
                                >
                                    Start
                                </button>
                            );
                        },
                    },
                    {
                        name: 'timepoint',
                        label: 'Week',
                        class: `text-center ${style.smallColumn}`,
                    },
                    {
                        name: 'weekStartDate',
                        label: 'Dates',
                        render: (value, row) => {
                            return `${format(row.weekStartDate, dateFormat)} - ${format(row.weekEndDate, dateFormat)}`;
                        },
                        class: style.mediumColumn,
                    },
                    {
                        name: 'numOfActivities',
                        label: 'Activities',
                        render: (value, row) => <Activities value={value} row={row} />,
                    },
                ]}
                rowKey="deploymentId"
                noRecordsMessage="You have not completed any tasks yet"
                wrapperClassName={`${style.table} ${style.deployments} ${style.moreSpecific}`}
                showPerPageOptions={false}
                hideShowingRowsSummary
            />
        </div>
    );
};

export default ProviderDashboard;
