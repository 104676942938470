import React from 'react';
import { format, isFuture } from 'date-fns';
import IconCheck from 'cccisd-icons/checkmark';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import SdqDeployment from './SdqDeployment';
import { dateFormat } from '../../pages/ProviderDashboard/Dashboard';

const SdqLauncher = props => {
    const { loadData, row, timepoint, value, sdqDeployment, loading } = props;

    // prevents flicker
    if (loading) {
        return <span />;
    }

    if (value && value.toLowerCase() === 'complete') {
        return (
            <>
                <span style={{ color: 'green' }}>
                    <IconCheck spaceRight />
                </span>
                Completed {format(new Date(row[`time${timepoint}.completedAt`]), dateFormat)}
            </>
        );
    }

    function getParticipantName() {
        if (row['user.firstName'] || row['user.lastName']) {
            return row['user.firstName'] + ' ' + row['user.lastName'];
        }

        return row['user.username'];
    }

    const isDisabled = !sdqDeployment.isOpen;
    if (isDisabled && (!sdqDeployment.opensAt || !sdqDeployment.closesAt)) {
        return '-';
    }

    if (!isDisabled || isFuture(new Date(sdqDeployment.opensAt))) {
        const trigger = (
            <button className="btn btn-xs btn-success" type="button" disabled={isDisabled}>
                {(value && value.toLowerCase()) === 'in progress' ? 'Resume' : 'Start'}
            </button>
        );

        return (
            <Modal
                title={`Strengths and Difficulties Questionnaire - ${getParticipantName()}`}
                size="large"
                render={() => <SdqDeployment {...props} />}
                trigger={
                    isFuture(new Date(sdqDeployment.opensAt)) ? (
                        <Tooltip
                            title={`Opens ${format(new Date(sdqDeployment.opensAt), dateFormat)}`}
                        >
                            {trigger}
                        </Tooltip>
                    ) : (
                        trigger
                    )
                }
                triggerDisabled={isDisabled}
                afterClose={() => loadData()}
            />
        );
    }

    return (
        <Tooltip
            title={`Incomplete - Closed on ${format(new Date(sdqDeployment.closesAt), dateFormat)}`}
        >
            <span>-</span>
        </Tooltip>
    );
};

export default SdqLauncher;
