import React, { useContext } from 'react';
import Loader from 'cccisd-loader';
import { Redirect } from 'cccisd-react-router';
import Dashboard from './Dashboard';
import { IsStudySiteContext } from '../../context/IsStudySite';

const ProviderDashboard = () => {
    const { isLoading, isInstructor, isStudySite } = useContext(IsStudySiteContext);

    if (!isInstructor) {
        return <Redirect to="/" />;
    }

    if (isLoading || isStudySite === null) {
        return <Loader loading />;
    }

    if (isStudySite === false) {
        return <Redirect to="/participants" />;
    }

    return <Dashboard />;
};

export default ProviderDashboard;
