import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Header, { Navbar, NavbarLink, ReturnToUserLink, UserDropdown } from 'cccisd-header';
import { IsStudySiteContext } from '../../context/IsStudySite';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const NavBar = ({ className }) => {
    const { isLoading, isInstructor, isStudySite } = useContext(IsStudySiteContext);
    const isLearner = Fortress.user.acting.role.handle === 'learner';

    if (isInstructor) {
        if (isLoading) {
            return <div style={{ height: '52px' }} />;
        }

        return (
            <header>
                <Navbar className={className} logo="SELweb R & D">
                    <ul className="nav navbar-nav navbar-right">
                        <ReturnToUserLink />
                        {isStudySite === true && (
                            <NavbarLink to="/dashboard" highlight="/dashboard">
                                Check In
                            </NavbarLink>
                        )}
                        <NavbarLink to="/participants" highlight="/participants">
                            Participants
                        </NavbarLink>
                        <UserDropdown allowSwitchRoles />
                    </ul>
                </Navbar>
            </header>
        );
    }

    return (
        <Header
            className={`${className} ${isLearner ? style.learnerHeader : ''}`}
            logo="SELweb R & D"
            noLogoLink={isLearner}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
