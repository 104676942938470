import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'cccisd-react-router';
import Loader from 'cccisd-loader';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;
const localStorageKey = 'cccisd-logout-on-page-refresh';

/* This Component prevents the students from doing the following:
 *   - clicking Back to get to teacher dashboard
 *   - going to any page except /assignment
 *   - closing their browser without logging out
 */
const SecureStudentWrapper = props => {
    const isLearner = Fortress.user.acting.role.handle === 'learner';
    const isLoginAs = Fortress.user.acting.id !== Fortress.user.actual.id;

    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (window.localStorage.getItem(localStorageKey) === 'true') {
            window.localStorage.setItem(localStorageKey, 'false');
            window.location = Boilerplate.route('api.nexus.logout');
        } else if (isLearner && !isLoginAs) {
            window.localStorage.setItem(localStorageKey, 'true');
        }
    }, []);

    useEffect(() => {
        if (!isLearner || ['/assignment', '/api/user/logout'].includes(location.pathname)) {
            setIsLoading(false);
            return;
        }

        if (location.pathname !== '/assignment') {
            history.push('/assignment');
            return;
        }

        setIsLoading(false);
    }, [isLearner, location.pathname]);

    if (isLoading) {
        return <Loader loading />;
    }

    return props.children;
};

export default SecureStudentWrapper;
