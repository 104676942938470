import React from 'react';
import { format, isPast, isFuture } from 'date-fns';
import Axios from 'cccisd-axios';
import IconCheck from 'cccisd-icons/checkmark';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';
import { dateFormat } from '../../pages/ProviderDashboard/Dashboard';

const Appdefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const Env = window.cccisd.env;

const StudentLauncher = ({ row, timepoint }) => {
    function launchStudent() {
        return Axios.post(Boilerplate.route('api.nexus.pawn.changeTo'), {
            pawnId: row['pawn.pawnId'],
            pawnHash: row['pawn.pawnHash'],
        })
            .then(response => {
                if (response.status === 200) {
                    return Axios.post(Boilerplate.route('app.lastLogin'));
                }
                notification({
                    message: 'Could not launch Participant survey at this time',
                    type: 'danger',
                });
            })
            .then(() => {
                const url = (Env.url.endsWith('/') ? Env.url : Env.url + '/') + 'assignment';
                window.location = url;
            });
    }

    const sessions = row['parentGroup.class.selectedAssignmentPlan.sessionList'] || [];
    const session = sessions.find(sess => Number(sess.timepoint) === Number(timepoint));
    if (!session) {
        return <span>-</span>;
    }

    // unflatten progress
    const progress = {
        timepoint: row[`time${timepoint}.deployment.timepoint`],
        startedAt: row[`time${timepoint}.startedAt`],
        completedAt: row[`time${timepoint}.completedAt`],
        status: row[`time${timepoint}.status`],
    };

    if (progress && progress.completedAt) {
        return (
            <>
                <span style={{ color: 'green' }}>
                    <IconCheck spaceRight />
                </span>
                {`Completed ${format(new Date(progress.completedAt), dateFormat)}`}
            </>
        );
    }

    if (session.opensAt && isFuture(new Date(session.opensAt))) {
        return (
            <Tooltip title={`Opens on ${format(new Date(session.opensAt), dateFormat)}`}>
                <button className="btn btn-xs btn-primary" type="button" disabled>
                    Launch
                </button>
            </Tooltip>
        );
    }

    if (session.closesAt && isPast(new Date(session.closesAt))) {
        return progress && progress.startedAt ? (
            <span>Incomplete</span>
        ) : (
            <span>Did Not Start</span>
        );
    }

    if (
        ((!session.opensAt || isPast(new Date(session.opensAt))) &&
            isFuture(new Date(session.closesAt))) ||
        !session.closesAt
    ) {
        return (
            <Tooltip
                title={`Launch ${
                    Appdefs.pawn.roles.find(r => r.handle === 'learner').label
                } survey`}
            >
                <button className="btn btn-xs btn-primary" type="button" onClick={launchStudent}>
                    {progress && progress.startedAt ? 'Resume' : 'Launch'}
                </button>
            </Tooltip>
        );
    }

    return <span>-</span>;
};

export default StudentLauncher;
