import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Modal from 'cccisd-modal';
import { dateFormat } from '..';
import style from './style.css';

const Activities = ({ value, row }) => {
    const checkIsMobile = () => window.innerWidth <= parseInt(style.mobileWidth, 10);
    const checkIsLarge = () => window.innerWidth >= parseInt(style.largeWidth, 10);
    const [isMobile, setIsMobile] = useState(checkIsMobile());
    const [isLarge, setIsLarge] = useState(checkIsLarge());

    useEffect(() => {
        function toggleMobile() {
            const smallScreen = checkIsMobile();
            if (!isMobile && smallScreen) {
                setIsMobile(true);
            } else if (isMobile && !smallScreen) {
                setIsMobile(false);
            }

            const largeScreen = checkIsLarge();
            if (!isLarge && largeScreen) {
                setIsLarge(true);
            } else if (isLarge && !largeScreen) {
                setIsLarge(false);
            }
        }

        window.addEventListener('resize', toggleMobile);
        return () => window.removeEventListener('resize', toggleMobile);
    }, [isMobile, isLarge]);

    function renderActivities(modalProps) {
        const closeButton = modalProps?.closeModal && (
            <button
                className={`btn btn-default ${style.closeButton}`}
                onClick={() => modalProps.closeModal()}
                type="button"
            >
                Close
            </button>
        );

        if (Number(value) === 0) {
            return (
                <>
                    <div>none</div>
                    {closeButton}
                </>
            );
        }

        const activities = Object.keys(row.devTags)
            .reduce((allActivities, currKey) => {
                const val = row.devTags[currKey];
                if (
                    !val ||
                    ['implementation_activities', '__typename'].includes(currKey) ||
                    currKey.includes('selected')
                ) {
                    return allActivities;
                }

                let surveyPageNumber;
                let ageBand;
                try {
                    surveyPageNumber = currKey.match(/_[1-9]{1}_/)[0].replaceAll('_', '');
                    ageBand = currKey.match(/_[k12345]{2}_/)[0].replaceAll('_', '');
                } catch (e) {
                    // meh...shrug
                }

                // handles case where activity was selected in the dropdown,
                // but then that dropdown was hidden by visibility logic in survey
                if (
                    surveyPageNumber &&
                    ageBand &&
                    row.devTags[`activities_${ageBand}_${surveyPageNumber}_selected`] !== '1'
                ) {
                    return allActivities;
                }

                const formattedVal = val.replace(/Activity [\d]{1,2}:/, '').trim();
                return [...allActivities, formattedVal];
            }, [])
            .sort();

        if (!isLarge) {
            return (
                <>
                    <ul className={style.activityList1Col}>
                        {activities.map((activity, i) => (
                            <li key={i}>{activity}</li>
                        ))}
                    </ul>
                    {modalProps?.closeModal && (
                        <button
                            className={`btn btn-default ${style.closeButton}`}
                            onClick={() => modalProps.closeModal()}
                            type="button"
                        >
                            Close
                        </button>
                    )}
                </>
            );
        }

        const perColumn = Math.ceil(activities.length / 3);
        const activitiesCol1 = activities.slice(0, perColumn);
        const activitiesCol2 = activities.slice(perColumn, 2 * perColumn);
        const activitiesCol3 = activities.slice(2 * perColumn);

        return (
            <>
                <ul className={style.activityList3Col}>
                    <div className={style.column}>
                        {activitiesCol1.map((act, i) => (
                            <li key={`1_${i}`}>{act}</li>
                        ))}
                    </div>
                    <div className={style.column}>
                        {activitiesCol2.map((act, i) => (
                            <li key={`2_${i}`}>{act}</li>
                        ))}
                    </div>
                    <div className={style.column}>
                        {activitiesCol3.map((act, i) => (
                            <li key={`3_${i}`}>{act}</li>
                        ))}
                    </div>
                </ul>
                {modalProps?.closeModal && (
                    <button
                        className={`btn btn-default ${style.closeButton}`}
                        onClick={() => modalProps.closeModal()}
                        type="button"
                    >
                        Close
                    </button>
                )}
            </>
        );
    }

    if (isMobile) {
        return (
            <Modal
                render={renderActivities}
                trigger={
                    <button type="button" className="btn btn-link">
                        {value}
                    </button>
                }
                title={`Activities for ${format(row.weekStartDate, dateFormat)} - ${format(
                    row.weekEndDate,
                    dateFormat
                )}`}
            />
        );
    }

    return renderActivities();
};

export default Activities;
