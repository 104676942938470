import React, { useState, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import query from './studySite.graphql';

export const IsStudySiteContext = React.createContext({
    isLoading: false,
    isInstructor: false,
    isStudySite: true,
});

const Fortress = window.cccisd.fortress;

const IsStudySite = props => {
    const isInstructor = Fortress.user.acting.role.handle === 'instructor';
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        async function getData() {
            const resp = await client.query({
                query,
                variables: {},
                fetchPolicy: 'network-only',
            });

            const isStudy = !!resp?.data?.roles?.instructor?.parentGroup?.site?.fields?.isStudySite;
            setData(isStudy);
            setIsLoading(false);
        }

        if (isInstructor) {
            getData();
        }
    }, []);

    return (
        <IsStudySiteContext.Provider value={{ isLoading, isInstructor, isStudySite: data }}>
            {props.children}
        </IsStudySiteContext.Provider>
    );
};

export default IsStudySite;
