import React from 'react';
import { addHours, addWeeks, format, isAfter, isSunday, subDays } from 'date-fns';
import style from './style.css';

/*
 * Displays the student launcher button or status, depending on conditions
 */
const ImplementationWeek = settings => {
    return ({ value, row, loadData }) => {
        const isYear2 = Number(settings.week) > 52;
        const weekNum = isYear2 ? parseInt(settings.week, 10) - 52 : parseInt(settings.week, 10);
        const grayCell = <div className={style.cell} />;

        // add 12 hours to get correct day of week when converting from UTC (database)
        let startDate = isYear2
            ? row['fields.startDate2'] && addHours(new Date(row['fields.startDate2']), 12)
            : row['fields.startDate'] && addHours(new Date(row['fields.startDate']), 12);
        if (!startDate) {
            console.info('No start date for Site ' + row['group.label']);
            return grayCell;
        }

        // open Fidelity surveys the Sunday before the program starts
        while (!isSunday(startDate)) {
            startDate = subDays(startDate, 1);
        }

        const endDate = isYear2 ? row['fields.endDate2'] : row['fields.endDate'];
        const isStudySite = row['fields.isStudySite'];
        const now = new Date();
        const weekCutoff = addWeeks(new Date(startDate), weekNum - 1);
        const isShouldBeComplete = isStudySite && isAfter(now, weekCutoff);

        if (
            !isShouldBeComplete ||
            (endDate && isAfter(weekCutoff, new Date(endDate))) ||
            (startDate && isAfter(new Date(startDate), now))
        ) {
            return grayCell;
        }

        const weekOf = addWeeks(new Date(startDate), weekNum - 1);
        return (
            <div className={`${style.cell} ${value ? style.complete : style.incomplete}`}>
                <p className={`${style.status} ${value ? 'text-success' : 'text-danger'}`}>
                    {value ? 'Complete' : 'Incomplete'}
                </p>
                <span className={style.weekOf}>Week of {format(weekOf, 'MM/DD/YYYY')}</span>
            </div>
        );
    };
};

export default ImplementationWeek;
