import React from 'react';
import IconCheck from 'cccisd-icons/checkmark';
import style from './style.css';

/*
 * Displays the student launcher button or status, depending on conditions
 */
export default settings => {
    return ({ value, row, loadData }) => {
        if (value && value.toLowerCase() === 'complete') {
            return (
                <>
                    <span className={style.complete}>
                        <IconCheck spaceRight />
                    </span>
                    Complete
                </>
            );
        }

        if (!value || value.toLowerCase() === 'not started') {
            return <span>-</span>;
        }

        return <span>{value}</span>;
    };
};
