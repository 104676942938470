import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import query from './deployment.graphql';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

const SdqDeployment = ({ row, timepoint }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [deploymentId, setDeploymentId] = useState(null);

    useEffect(() => {
        async function getData() {
            const resp = await client.query({
                query,
                variables: { timepoint },
                fetchPolicy: 'network-only',
            });

            setDeploymentId(resp?.data?.deployments?.deployment?.deploymentId);
            setIsLoading(false);
        }

        getData();
    }, []);

    if (isLoading) {
        return <Loader loading />;
    }

    if (!deploymentId) {
        return (
            <div className="alert alert-danger">
                <p>We could not find a SDQ deployment for timepoint {timepoint}</p>
            </div>
        );
    }

    return (
        <DeploymentPlayer
            deploymentId={deploymentId}
            disableLayout
            flowProps={{
                defaultValueVariables: {
                    sdqName: row['user.fullName'] || '',
                    sdqBirthdate: {
                        date: row['fields.birthdate'] || '',
                        startedAt: format(new Date(), 'YYYY-MM-DD'),
                    },
                },
            }}
            pawnId={row['pawn.pawnId']}
            pawnHash={row['pawn.pawnHash']}
        />
    );
};

export default SdqDeployment;
