import React, { useState, useEffect } from 'react';
import color from 'color';
import { client } from 'cccisd-apollo';
import { getColor } from 'cccisd-color-picker';
import { AssignmentPlanPlayer, DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import Header from '../../components/NavBar';
import checkAssentQuery from './checkAssent.graphql';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const StudentAssignment = props => {
    const [isConfirmedName, setIsConfirmedName] = useState(false);
    const [isLoadingAssent, setIsLoadingAssent] = useState(true);
    const [assentData, setAssentData] = useState(null);

    async function getAssentData(isCompleteOnce) {
        setIsLoadingAssent(true);
        const resp = await client.query({
            query: checkAssentQuery,
            fetchPolicy: 'network-only',
        });

        const learner = resp?.data?.roles?.learner;
        const needsAssent = learner?.fields?.needsAssent || false;
        // Codebook should map answer to 0 or 1
        const assentProvided = Number(learner?.assignmentProgress?.devTags?.student_assent || false);
        setAssentData({ needsAssent, assentProvided, isCompleteOnce });
        setIsLoadingAssent(false);
    }

    useEffect(() => {
        getAssentData(false);
    }, []);

    let color1 = color(getColor('primaryColor')).lighten(0.3);
    let color2 = color(getColor('primaryColor')).hex();
    const studentBackground = {
        background: `radial-gradient(ellipse, ${color1} 40%, ${color2} 100%)`,
    };

    if (!isConfirmedName) {
        let displayName = Fortress.user.acting.user.fullName;
        if (!displayName) {
            const firstName = Fortress.user.acting.user.first_name;
            const lastName = Fortress.user.acting.user.last_name;
            displayName = firstName || lastName ? `${firstName} ${lastName}` : Fortress.user.acting.user.username;
        }

        return (
            <div className={style.confirmName}>
                <Header />
                <div className={style.content} style={studentBackground}>
                    <div className={style.prompt}>
                        <p>
                            Welcome! Are you <b>{displayName}</b>?
                        </p>
                        <div>
                            <button
                                className="btn btn-lg btn-primary"
                                onClick={() => setIsConfirmedName(true)}
                                type="button"
                            >
                                Yes
                            </button>
                            <button
                                className="btn btn-lg btn-danger"
                                onClick={() => {
                                    window.location = Boilerplate.route('api.nexus.logout');
                                }}
                                type="button"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (isLoadingAssent || !assentData) {
        return (
            <Loader loading>
                <div className={style.confirmName}>
                    <Header />
                    <div className={style.content} style={studentBackground} />
                </div>
            </Loader>
        );
    }

    if (assentData.needsAssent && !assentData.assentProvided) {
        return assentData.isCompleteOnce ? (
            <div className={style.confirmName}>
                <Header />
                <div className={style.content} style={studentBackground}>
                    <div className={style.prompt}>
                        <p className="text-center">
                            Assent must be provided to continue. You may log out and assent at a later time. Please
                            contact a program administrator for assistance.
                        </p>
                    </div>
                </div>
            </div>
        ) : (
            <DeploymentPlayer
                pawnId={Fortress.user.acting.id}
                pawnHash={Fortress.user.acting.random_hash}
                deploymentHandle="assent"
                onComplete={() => getAssentData(true)}
                shouldSaveLanguage
            />
        );
    }

    function onComplete() {
        setTimeout(() => {
            window.location = Boilerplate.route('api.nexus.logout');
        }, 10000);
    }

    function renderError(errMessage) {
        return (
            <div>
                <Header />
                <div className="container">
                    <div className="alert alert-danger">
                        <p>{errMessage}</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <AssignmentPlanPlayer
            {...props}
            deploymentProps={{ onComplete, shouldSaveLanguage: true }}
            renderError={renderError}
        />
    );
};

export default StudentAssignment;
