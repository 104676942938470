import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'cccisd-react-router';
import { AdminRoutes, Dashboard as AppdefDashboard, Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { Quest } from 'cccisd-laravel-assignment';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';
import SecureStudentWrapper from './layouts/SecureStudentWrapper';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome';
import ProviderDashboard from './pages/ProviderDashboard';
import ProviderParticipants from './pages/ProviderParticipants';
import StudentAssignment from './pages/StudentAssignment';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutSecondaryNav = defaultProps({ showSecondaryNav: true })(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AppdefDashboard,
    AppdefManage,
    ProviderDashboard,
    ProviderParticipants,
    StudentAssignment,
    Welcome,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
    QuestLayout,
};

export default () => (
    <SecureStudentWrapper>
        <Switch>
            {AppDefinitions.routes.map(route => {
                const Component = componentMatch[route.componentHandle];
                if (!Component) {
                    return null;
                }

                return (
                    <AppRoute
                        key={route.handle}
                        path={route.url}
                        component={Component}
                        componentProps={route.componentProps}
                        exact
                        layout={
                            'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayoutFluid
                        }
                    />
                );
            })}
            <AppRoute path="/" component={Welcome} exact />
            <AppRoute path="/welcome" component={Welcome} />

            <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />

            {/* Laravel packages routes */}
            {AdminRoutes(MainLayoutFluid)}

            <AppRoute
                path="/account"
                layout={null}
                component={Nexus}
                componentProps={{
                    defaultLayout: MainLayout,
                    registrationSurveyLayout: QuestLayout,
                }}
            />

            <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

            {/* Not found page */}
            <AppRoute component={NotFound} />
        </Switch>
    </SecureStudentWrapper>
);
